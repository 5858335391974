@import "../global/mixins";

.featuredBanner {
    margin-top: calc(var(--nav-height));
    // overflow: hidden;
    position: relative;
    z-index: 1;

	.subtitle {
        @include font(20, 30);
		max-width: 60%;
        color: var(--white);
		margin-block: getRem(-32) getRem(48);

        @include breakpoint(mobile) {
            max-width: none;
        }
	}

    .objectType {
        color: var(--white);
        display: flex;
        align-items: center;
        margin: 0 0 getRem(24);

        span {
            @include font(14, 14);
            margin-left: getRem(4);
        }

        svg {
            width: getRem(16);
            height: auto;
            
            path {
                fill: var(--white);
            }
        }

        &.fgdType {
            svg {
                path {
                    stroke: var(--white);
                }
            }
        }
    }

    h1 {
        max-width: 60%;
        color: var(--white);
        @include font(72, 80);

        @include breakpoint(mobile) {
            max-width: none;
            @include font(36, 45);
        }
    }

    .description {
        color: var(--white);
        margin: 0 0 getRem(24);
    }

    .meta {
        margin-top: 0;

        .metaData {
            display: flex;
            flex-wrap: wrap;
            grid-gap: getRem(16);
            position: relative;
            z-index: 2;
        }

        p,
        span {
            color: var(--white);
            @include font(14, 18);
        }

        svg {
            // width: auto;
            height: auto;
            max-height: getRem(16);
            margin-right: getRem(8);

            path,
            circle {
                stroke: var(--white);
            }
        }

        .views {
            svg {
                path {
                    fill: var(--white);
                    stroke: transparent;
                }
            }
        }

        .bottomMeta {
            margin-top: getRem(24);
            position: relative;
            z-index: 1;

            span {
                @include font(16, 18);
            }
        }

        :global {
            .thumbsUpItem {
                padding: 0;
            }

            button.reactUp {
                overflow: hidden;
                padding: getRem(9);
                position: relative;

                .reaction-overlay {
                    width: 100%;
                    height: 100%;
                    font-size: 2px;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                }
            }

            .actionButton {
                &>span {
                    margin-right: 0 !important;
                    transition: all .1s ease-in-out;
                }

                &:hover {
                    span {
                        color: var(--black);
                    }
                }
            }
        }

        .followContainer {
            button {

                p,
                span {
                    margin-right: 0;
                }
            }
        }

        .country {
            svg {
                path {
                    stroke: transparent;
                }
            }
        }
    }

    .image {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
    }

    .textContainer {
        padding-top: getRem(145);
        padding-bottom: getRem(166);
        position: relative;
        z-index: 1;

        @include breakpoint(mobile) {
            padding: getRem(60) 0;
        }
    }
}

.featuredCards {
    background: var(--light-grey);
    padding: getRem(40) 0 getRem(48) 0;

    .wrapper {
        display: grid;
        grid-template-columns: repeat(3, calc(33% - 22px));
        grid-gap: getRem(33);

        @include breakpoint(tablet) {
            grid-template-columns: repeat(2, calc(50% - 12px));
            grid-gap: getRem(24);
        }

        @include breakpoint(mobile) {
            display: flex;
            flex-direction: column;
            grid-gap: getRem(16);
        }

        &>div {
            grid-column: auto;

            @include breakpoint(mobile) {
                &:not(:first-child) {
                    margin-top: getRem(16);
                }
            }

            h3 {
                @include font(20, 24);
                margin: 0;
            }

            &>div {
                padding: getRem(16) getRem(16) getRem(12);

                &>div:last-child {
                    border: none;
                    padding-bottom: 0;

                    &>div {
                        &>div {
                            p:first-child {
                                // display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.recommended {
    margin-top: getRem(112);
    position: relative !important;
    z-index: 100 !important;

    @include breakpoint(mobile) {
        margin-top: getRem(40);
    }

    .hidden {
        display: none !important;
    }

    .buttonsContainer {
        display: flex;

        button {
            margin: auto;
        }
    }
}

.trending {
    background: url('/../../images/homepage-grey-swoosh.svg') 50% 100% no-repeat;
    background-size: 100% 65%;
    position: relative;
}

.newest {
    margin-top: getRem(118);

    @include breakpoint(mobile) {
        margin-top: getRem(40);
    }

    .tabs {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        column-gap: getRem(32);
        row-gap: getRem(32);
        list-style: none;
        padding: 0;
        text-align: center;

        @include breakpoint(mobile) {
            column-gap: getRem(24);
            row-gap: getRem(24);
        }

        li {
            display: inline-block;
            vertical-align: top;
        }

        button {
            background: none;
            border: none;
            border-bottom: getRem(3) solid transparent;
            @include font(24, 30);

            @include breakpoint(mobile) {
                @include font(18, 30);
            }

            &.active {
                font-weight: 700;
                border-color: var(--red);
            }
        }
    }
}